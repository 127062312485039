.join {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}

.left_j {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  word-spacing: 1rem;
}

.left_j > hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
}

.left_j > div {
  display: flex;
}

.right_j {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.email_container {
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}

.email_container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgrayz);
}
::placeholder {
  color: var(--lightgray);
}
.btn {
  color: white;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
  border: none;
  background-color: var(--orange);
}

@media screen and (max-width: 768px) {
  .join {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .left_j {
    font-size: x-large;
    flex-direction: column;
    display: flex;
    gap: 1rem;
    margin-left: 1.9rem;
  }
  hr {
    display: none;
  }
  /* .email_container {
    width: 13rem;
    height: 1rem;
  } */
  .place {
    font-size: small;
    text-align: center;
  }
  .btn {
    position: relative;

    right: 1rem;
    font-size: xx-small;
  }
}
