.footer_container {
  position: relative;
}

.footer_container > hr {
  border: 1px solid var(--orange);
}

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}

.social {
  display: flex;
  gap: 4rem;
}

.social > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.footer_l {
  width: 32rem;
  height: 23rem;
  position: absolute;
  top: 2rem;
  left: 15rem;
  background: rgb(255, 0, 0);
  border-radius: 50%;
  filter: blur(250px);
  z-index: -9;
}
.footer_r {
  width: 32rem;
  height: 23rem;
  position: absolute;
  top: 2rem;
  right: 15rem;
  background: rgb(255, 94, 1);
  border-radius: 50%;
  filter: blur(250px);
  z-index: -9;
}
@media screen and (max-width: 768px) {
  .footer_container {
    position: relative;
    bottom: 5rem;
  }
  .logo_f > img {
    width: 15rem;
  }
}
