.test {
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
  height: fit-content;
}

.left_t {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
  flex: 1 1;
  height: fit-content;
}

.left_t > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}
.left_t > :nth-child(2),
.left_t > :nth-child(3) {
  font-weight: bold;
  font-size: 3rem;
}
.left_t > :nth-child(4) {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}

.right_t {
  flex: 1 1;
  height: 25rem;
}
.right_t > img {
  position: relative;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  /* top: 2rem; */
  bottom: 37rem;
  /* page-break-after: always; */
  left: 11rem;
}

.right_t > :nth-child(1) {
  position: relative;
  width: 17rem;
  height: 20rem;
  border: 2px solid orange;
  background-color: transparent;
  right: 9rem;
  left: 10rem;
  top: 0.9rem;
}
.right_t > :nth-child(2) {
  position: relative;
  width: 17rem;
  height: 19rem;
  background: var(--planCard);
  right: 7rem;
  /* top: 4rem; */
  bottom: 16rem;
  left: 12rem;
}

.arrows {
  display: flex;
  gap: 1rem;
  position: relative;
  bottom: 38rem;
}

.arrows > img {
  width: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 786px) {
  .test {
    flex-direction: column;
  }
  .left_t > :nth-child(2),
  .left_t > :nth-child(3) {
    font-size: xx-large;
  }
  .right_t {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .right_t > div {
    position: relative;
    display: none;
  }
  .right_t > img {
    top: 0px;
    left: 0px;
    position: relative;
    align-self: center;
  }
  .arrows > img {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}
